import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const CSShadowPalacePage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_overview.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace</h1>
          <h2>
            In this guide you will find tips and tricks that will help you
            conquer the Shadow Palace.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
      <div className="page-content">
        <SectionHeader title="About Shadow Palace" />
        <StaticImage
          src="../../../images/counterside/generic/guide_shadoww.webp"
          alt="Shadow Palace"
        />
        <p>
          Shadow Palace is PVE mode where you need to conquer a dungeon with 5
          floors and 5 stages on each one, but every stage has different
          restrictions - for example some ban Rangers, other Strikers.
        </p>
        <p>
          How it works?{' '}
          <strong>
            Each day, you get one Entry Ticket and you can save up to 7
          </strong>
          . You need a Ticket to enter the floor of your choosing and you get 3
          Hearts in return. You lose 1 Heart when you fail a stage and if you
          lose all 3 you will be kicked out from the floor and will need another
          Ticket to enter.
        </p>
        <p>
          What's important is that{' '}
          <strong>the Skip option isn't available in this mode</strong> which
          means you have to create a set of teams that are able to tackle one of
          the floors and also do it on auto with 100% win rate.
        </p>
        <SectionHeader title="Rewards" />
        <p>
          Shadow Palace is the only source of <strong>Spectral Gear</strong> in
          the game - gear that is Best in Slot for majority of Soldiers and
          Mechs, but also some Counters can use it.
        </p>
        <p>
          But Spectral Gear isn't the only thing you can farm in Shadow Palace.
          Binaries - both Set and Tuning - are also available in the shop.
        </p>
        <p>
          To learn more about the gear, rewards and the shop, check our other
          guides:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Rewards"
            link="/counter-side/guides/shadow-palace-rewards"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_rewards.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Shop guide"
            link="/counter-side/guides/shadow-palace-shop"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_shop.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Spectral gear"
            link="/counter-side/guides/shadow-palace-spectral"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_spectral.png"
                alt="Shadow Palace"
              />
            }
          />
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297253"></div>
        <SectionHeader title="Floors" />
        <p>
          Shadow Palace is split into 5 floors and each has a different theme
          that forces you to use different kind of units and teams to counter
          whatever awaits you in the stages.
        </p>
        <p>
          We've split our guides into 6 parts: one for generic tips that apply
          to all floors and then one for each floor.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Tips for all floors"
            link="/counter-side/guides/shadow-palace-tips"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_all.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 1"
            link="/counter-side/guides/shadow-palace-floor-1"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_1.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 2"
            link="/counter-side/guides/shadow-palace-floor-2"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_2.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 3"
            link="/counter-side/guides/shadow-palace-floor-3"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_3.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 4"
            link="/counter-side/guides/shadow-palace-floor-4"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_4.png"
                alt="Shadow Palace"
              />
            }
          />
          <CategoryCard
            title="Floor 5"
            link="/counter-side/guides/shadow-palace-floor-5"
            image={
              <StaticImage
                src="../../../images/counterside/categories/shadow_floor_5.png"
                alt="Shadow Palace"
              />
            }
          />
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalacePage;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace guide | Counter Side | Prydwen Institute"
    description="Prepare yourself for the Shadow Palace."
  />
);
